import React, { useState, useEffect } from "react";

import AddReviewCard from "./AddReviewCard";
import ReviewCard from "./ReviewCard";

// redux
import { useSelector } from "react-redux";

const Reviews = () => {
  const [reviewList, setReviewList] = useState([]);

  const reduxState = useSelector((globalState) => globalState.reviews.reviews);

  useEffect(() => {
    reduxState && setReviewList(reduxState);
  }, [reduxState]);

  return (
    <div className="w-full h-full flex-col md:flex md:flex-row relative gap-5 container mx-auto my-3">
      <div className="w-full md:w-8/12 flex flex-col gap-3 overflow-auto h-[35rem]">
        <div className="md:hidden mb-4 flex flex-col gap-2">
          <AddReviewCard />
        </div>
        {reviewList.map((review, index) => (
          <ReviewCard {...review} key={index} />
        ))}
      </div>
      <aside
        style={{ height: "fit-content" }}
        className="hidden md:flex md:w-4/12 sticky rounded-xl top-20 bg-white py-4 px-4 shadow-md flex-col gap-4"
      >
        <AddReviewCard />
      </aside>
    </div>
  );
};

export default Reviews;
