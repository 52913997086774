import React, { useRef, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
// import { FaGlobe } from "react-icons/fa";
import "../../css/hero_section.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

// components
import SignIn from "../auth/Signin";

// redux
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../redux/reducers/auth/auth.action";
import { clearUser } from "../../redux/reducers/user/user.action";

const MobileNav = ({ user, signIn }) => {
  const [toggleClick, setToggleClick] = useState(false);

  const handleClick = () => {
    setToggleClick((toggleClick) => !toggleClick);
  };

  let display = toggleClick ? "" : "hidden";

  const location = useLocation();

  const menuRef = useRef();
  const toggleRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SignOut = () => {
    dispatch(signOut());
    dispatch(clearUser());
    navigate("/");
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current.contains(e.target) &&
        !toggleRef.current.contains(e.target)
      ) {
        setToggleClick(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div className="flex justify-between md:hidden px-3 py-1 relative">
        <div className="flex justify-center items-center px-2">
          <Link to="/" smooth>
            <img
              src="/images/DIGI_LOGO.JPG"
              alt=""
              className="w-32  rounded-xl border-2 border-blue-300"
            />
          </Link>
          <div className="flex justify-start items-center align-middle gap-8  digi_logo text-center text-md sm:px-10 px-3 uppercase text-black font-700">
            <span>Digi Common Services</span>
          </div>
        </div>
        <span
          className="flex bg-blue-400 text-white shadow-md px-2 items-center rounded-sm sm:mx-3 mx-2 py-2 my-auto"
          ref={toggleRef}
        >
          <RxHamburgerMenu
            onClick={() => {
              setToggleClick(!toggleClick);
            }}
          />
        </span>
        <div
          className={`flex flex-col absolute top-16 right-0 w-auto justify-center text-md bg-white gap-2 px-8 rounded-sm text-gray-600 ${display} shadow-md`}
          ref={menuRef}
          onClick={handleClick}
        >
          <Link
            to="/"
            smooth
            className="text-black hover:font-bold transition ease-in delay-100 duration-100"
          >
            <span>Home</span>
          </Link>
          <Link
            to="#about"
            smooth
            className="text-black hover:font-bold transition ease-in delay-100 duration-100"
          >
            <span>About</span>
          </Link>
          <Link
            to="#services"
            smooth
            className="text-black hover:font-bold transition ease-in delay-100 duration-100"
          >
            <span>Services</span>
          </Link>
          <Link
            to="#notices"
            smooth
            className="text-black hover:font-bold transition ease-in delay-100 duration-100"
          >
            <span>Notices</span>
          </Link>
          <Link
            to="#contact"
            smooth
            className="text-black hover:font-bold transition ease-in delay-100 duration-100 "
          >
            <span>Contact</span>
          </Link>
          <div className="font-mono mx-auto px-1 my-2 bg-blue-400 rounded-sm ">
            <Link
              to="#login"
              smooth
              className="text-center cursor-pointer lg:text-xl tracking-wider md:text-xl text-white lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
            >
              {user?.fullName ? (
                <span onClick={SignOut}>Sign Out</span>
              ) : (
                <span onClick={signIn}>Login</span>
              )}
            </Link>
          </div>
          {location.pathname === "/addData" ? null : user?.fullName ? (
            <div className="font-mono mx-auto px-1 my-2 bg-blue-400 rounded-sm ">
              <Link
                to="addData"
                className="text-center cursor-pointer lg:text-xl tracking-wider md:text-xl text-white lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
              >
                <span>Add Data</span>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const LargeNavbar = ({ user, signIn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const SignOut = () => {
    dispatch(signOut());
    dispatch(clearUser());
    navigate("/");
  };

  return (
    <>
      <div className="justify-around lg:py-3 md:py-2 hidden md:flex 2xl:mx-32 xl:mx-28 lg:mx-24">
        <div className="lg:px-16 md:px-5 px-3">
          <Link to="/" smooth>
            <div className="flex justify-start items-center gap-10 w-[5em]   digi_logo text-center lg:text-4xl md:text-3xl uppercase text-black font-700">
              {/* <FaGlobe className="lg:text-7xl md:text-5xl" /> */}
              <img
                src="/images/DIGI_LOGO.JPG"
                alt=""
                className="w-40 h-16 rounded-xl border-2 border-blue-300"
              />
            </div>
          </Link>
        </div>
        <div className="flex  justify-center items-center xl:gap-5 lg:gap-3 md:gap-2 font-mono mx-auto ">
          <Link
            to="/"
            smooth
            className="text-center  lg:text-xl text-md tracking-wider   text-black lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            <span>Home</span>
          </Link>
          <Link
            to="#about"
            smooth
            className="text-center  lg:text-xl tracking-wider text-black lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            <span>About</span>
          </Link>

          <Link
            to="#services"
            smooth
            className="text-center  lg:text-xl tracking-wider text-black lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            <span>Services</span>
          </Link>
          <Link
            to="#notices"
            smooth
            className="text-center  lg:text-xl tracking-wider text-black lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            <span>Notices</span>
          </Link>

          <Link
            to="#contact"
            smooth
            className="text-center  lg:text-xl tracking-wider text-black lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            <span>Contact</span>
          </Link>
        </div>
        <div className="font-mono mx-5 my-auto bg-blue-400 rounded-sm py-1">
          <div
            to="#"
            className="text-center cursor-pointer lg:text-xl tracking-wider md:text-xl text-white lg:px-3 md:px-1 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
          >
            {user?.fullName ? (
              <span onClick={SignOut}>
                <FaSignOutAlt />
              </span>
            ) : (
              <span onClick={signIn}>Login</span>
            )}
          </div>
        </div>
        {location.pathname === "/addData" ? null : user?.fullName ? (
          <div className="font-mono mx-5 my-auto bg-blue-400 rounded-sm py-1">
            <Link
              to="addData"
              className="text-center cursor-pointer lg:text-md tracking-wider md:text-sm text-white lg:px-2 hover:font-bold transition ease-in delay-100 hover:-translate-y-0.5 duration-100 uppercase"
            >
              <span>Add Data</span>
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

const Navbar = () => {
  const [openSignIn, setOpenSignIn] = useState(false);

  const openSignInModal = () => setOpenSignIn(true);

  const user = useSelector((globalStatte) => globalStatte.user);

  return (
    <>
      <SignIn isOpen={openSignIn} setIsOpen={setOpenSignIn} />
      <MobileNav user={user} signIn={openSignInModal} />
      <LargeNavbar user={user} signIn={openSignInModal} />
    </>
  );
};
export default Navbar;
