import React from "react";
import styled from "styled-components";
import { RiWhatsappFill } from "react-icons/ri";

const whatsapp = () => {
  return (
    <Wrapper>
      <div className="top-btn bg-green-400">
        <a href="https://wa.me/917017200216" target="_blank" rel="noreferrer">
          <RiWhatsappFill className="top-btn--icon" />
        </a>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  .top-btn {
    font-size: 1.7rem;
    width: 3rem;
    height: 3rem;
    color: white;
    background-color: rgb(35 218 33);
    box-shadow: rgb(155 199 233 / 57%) 0px 0px 6px 4px;
    border-radius: 50%;
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
      animation: gototop 1.12s linear infinite alternate-reverse;
    }

    @keyframes gototop {
      0% {
        transform: translateY(-0.2rem);
      }
      100% {
        transform: translateY(0.2rem);
      }
    }
  }
`;
export default whatsapp;
