import React, { useState } from "react";

// component
import GovtNoticeModal from "./noticeModals/GovtNoticeModal";

const AddGovtNotice = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="border-t-2 border-blue-500 pt-5">
        <GovtNoticeModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <h3 className="text-xl font-medium">
          Add Government Job related Notices From Here
        </h3>

        <button
          onClick={openModal}
          className="text-blue-400 text-lg font-serif text-left"
        >
          Add Notices
        </button>
      </div>
    </>
  );
};

export default AddGovtNotice;
