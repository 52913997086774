import React, { useEffect } from "react";
import About from "../components/about/About";
import Contect from "../components/contact/Contect";
import Gallery from "../components/gallary/Gallery";
import HeroSection from "../components/HeroSection/HeroSection";
import Navbar from "../components/HeroSection/Navbar";
import DigiServices from "../components/Services/DigiServices";
import PrintServices from "../components/Services/PrintServices";
import Map from "../components/map/MapView";
import Footer from "../components/footer/footer";
import GoToTop from "../components/GoToTop";
import Whatsapp from "../components/whatsapp";
import Reviews from "../components/reviews/Reviews";
import EduNotice from "../components/notice/EduNotice";
import GovtNotice from "../components/notice/GovtNotice";

// redux

import { useDispatch } from "react-redux";
import {
  getDigiService,
  getPrintService,
} from "../redux/reducers/services/service.action";
import { getReview } from "../redux/reducers/reviews/review.action";
import {
  getEduNotice,
  getGovtNotice,
} from "../redux/reducers/notice/notice.action";
const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDigiService());
    dispatch(getPrintService());
    dispatch(getReview());
    dispatch(getEduNotice());
    dispatch(getGovtNotice());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <HeroSection />

      <div className="md:flex flex-row-reverse gap-2 lg:container mx-auto w-full">
        <div className="md:w-2/5 mx-auto mt-20">
          <div id="service" className="flex justify-center items-center my-9">
            <span
              className="text-center text-sky-500 font-semibold md:text-4xl text-3xl  mx-auto"
              data-aos="fade-up"
            >
              Notice
            </span>
          </div>
          <EduNotice />
          <GovtNotice />
        </div>
        <div className="max-md:w-3/5">
          <DigiServices />
        </div>
      </div>
      <PrintServices />
      <Reviews />
      <About />
      <Gallery />
      <Contect />
      <Map />
      <GoToTop />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default HomePage;

// <HeroSection />
// <Services />
// <Prints />
