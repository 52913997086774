import axios from "axios";

//redux types
import { GET_USER } from "./user.type";

export const getUser = (_id) => async (dispatch) => {
  try {
    const User = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/${_id}`,
    });
    return dispatch({ type: GET_USER, payload: User.data });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};

export const getMySelf = () => async (dispatch) => {
  try {
    const User = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/`,
    });
    return dispatch({ type: "SELF", payload: { ...User.data.user } });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};

export const clearUser = () => async (dispatch) => {
  try {
    return dispatch({ type: "CLEAR_USER", payload: {} });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};
