import React, { useState, useEffect } from "react";
// import OurServices from "../../data/printsData.json";
// import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";

// redux

import { useSelector, useDispatch } from "react-redux";
import { deletePrintService } from "../../redux/reducers/services/service.action";

const PrintServices = () => {
  const [serviceList, setServiceList] = useState([]);

  const reduxState = useSelector(
    (globalState) => globalState.services.printServices
  );

  const user = useSelector((globalState) => globalState.user);

  useEffect(() => {
    reduxState && setServiceList(reduxState);
  }, [reduxState]);

  const dispatch = useDispatch();
  const deleteServiceCard = (_id) => {
    dispatch(deletePrintService(_id));
  };

  return (
    <section>
      <div id="service" className="flex justify-center items-center mt-8">
        <span
          className="text-center text-sky-500 font-semibold md:text-5xl text-2xl pb-3  mx-auto" // ke
          data-aos="fade-up"
        >
          Printing and Designing
        </span>
      </div>
      <div
        className="container grid print-bg rounded-md xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-8 lg:gap-6 md:gap-5 sm:gap-4 gap-3 px-7 w-full py-7 mx-auto"
        data-aos="fade-up"
      >
        {serviceList.map((data, key) => (
          <div
            className="flex flex-col justify-center items-start gap-3 border-2 border-blue-900 bg-white shadow-lg lg:p-8 md:p-5 sm:p-3 p-2 rounded-sm"
            key={key}
          >
            <div>
              <img
                src={data.img}
                alt={data.title}
                className="w-20 h-20 border-2 border-blue-700 object-contain rounded-full"
              />
            </div>
            <div className="flex flex-col gap-3 py-2">
              <h3 className="text-xl font-serif font-semibold text-gray-500 py-3">
                {data.title}
              </h3>
              <p className="text-sm text-gray-600 font-medium">
                {data.description}
              </p>
            </div>
            <a
              href="https://wa.me/919873355417"
              target="_blank"
              rel="noreferrer"
              className="bg-indigo-700 rounded-md text-white lg:text-md px-4 py-2 my-2"
            >
              <span className="flex gap-2 items-center justify-center">
                <BsWhatsapp /> Enquiry Now
              </span>
            </a>
            {user?.fullName ? (
              <button
                className="text-red-700 border border-red-700 text-xl py-2 rounded-lg px-4"
                onClick={() => deleteServiceCard(data._id)}
              >
                <AiFillDelete />
              </button>
            ) : null}
          </div>
        ))}
      </div>
    </section>
  );
};

export default PrintServices;
