import React from "react";
import { TiStarFullOutline } from "react-icons/ti";
import { AiFillDelete } from "react-icons/ai";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

//redux
import { useSelector, useDispatch } from "react-redux";
import { deleteReview } from "../../redux/reducers/reviews/review.action";

dayjs.extend(relativeTime);

const ReviewCard = (props) => {
  const user = useSelector((globalState) => globalState.user);

  const dispatch = useDispatch();

  const deleteReviewCard = (_id) => {
    dispatch(deleteReview(_id));
  };
  // console.log(props._id);
  return (
    <div className="my-3 flex flex-col gap-3 pb-4 px-10 border-b border-gray-300">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full">
            {props.img ? (
              <img
                src={props.img}
                alt="avatar"
                className="w-full h-full rounded-full object-cover border-2 border-blue-200"
              />
            ) : (
              <img
                src="https://cdn.pixabay.com/photo/2016/06/03/15/35/customer-service-1433639_640.png"
                alt="avatar"
                className="w-full h-full rounded-full object-cover border-2 border-blue-200"
              />
            )}
          </div>
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold">{props.name}</h3>
            <small className="text-gray-500">
              5 Reviews &#8226; 3 Followers
            </small>
          </div>
        </div>
        {user?.fullName ? (
          <button
            className="text-red-700 border border-red-700 text-xl py-2 rounded-lg px-4"
            onClick={() => deleteReviewCard(props._id)}
          >
            <AiFillDelete />
          </button>
        ) : null}
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <span className="text-white text-xs bg-green-700 gap-2 px-2 py-1 rounded-lg flex items-center">
            {props.rating} <TiStarFullOutline />
          </span>

          <small className="text-gray-500">
            {dayjs(props.createdAt).fromNow()}
          </small>
        </div>
        <div className="w-full">
          <p className="w-full text-gray-700 font-normal text-base">
            {props.reviewText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
