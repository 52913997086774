import {
  GET_DIGI_SERVICE,
  GET_PRINT_SERVICE,
  POST_DIGI_SERVICE,
  POST_PRINT_SERVICE,
  DELETE_DIGI_SERVICE,
  DELETE_PRINT_SERVICE,
} from "./service.type";

const initialState = {
  services: [],
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIGI_SERVICE:
      return {
        ...state,
        digiServices: [...action.payload],
      };
    case GET_PRINT_SERVICE:
      return {
        ...state,
        printServices: [...action.payload],
      };
    case POST_DIGI_SERVICE:
      return {
        ...state,
        digiServices: [action.payload, ...state.services],
      };
    case POST_PRINT_SERVICE:
      return {
        ...state,
        printServices: [action.payload, ...state.services],
      };
    case DELETE_DIGI_SERVICE:
      return {
        ...state,
        digiServices: state.digiServices.filter(
          (service) => service._id !== action.payload
        ),
      };
    case DELETE_PRINT_SERVICE:
      return {
        ...state,
        printServices: state.printServices.filter(
          (service) => service._id !== action.payload
        ),
      };
    default:
      return {
        ...state,
      };
  }
};

export default serviceReducer;
