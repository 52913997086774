import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import galleryPhotos from "../../data/images.json";
import Slider from "react-slick";

const Gallery = (props) => {
  return (
    <>
      <div className="text-center p-4 hover:shadow-lg hover:shadow-gray-400 h-[18rem] 2xl:w-[24rem]">
        <img
          src={props.src}
          alt="photos"
          className="rounded h-[18rem] 2xl:w-[24rem] aspect-square object-contain mix-blend-color-burn"
        />
      </div>
    </>
  );
};

const GalleryImages = () => {
  useEffect(() => {
    Aos.init();
  });
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 3000,
    autoplaySpeed: 500,
    cssEase: "linear",
    dragable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section id="photos">
        <div id="gallery" className="flex justify-center items-center mt-8">
          <span
            className="text-center text-sky-500 font-semibold md:text-5xl text-4xl mx-auto"
            data-aos="fade-up"
          >
            Gallery
          </span>
        </div>
        <div className="px-7 w-full py-7" data-aos="fade-up">
          <Slider {...settings}>
            {galleryPhotos.map((image, index) => (
              <Gallery src={image} key={index} />
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default GalleryImages;
