import { GET_REVIEW, POST_REVIEW, DELETE_REVIEW } from "./review.type";

const initialState = {
  reviews: [],
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW:
      return {
        ...state,
        reviews: action.payload,
      };
    case POST_REVIEW:
      return {
        ...state,
        reviews: [action.payload, ...state.reviews],
      };
    case DELETE_REVIEW:
      return {
        ...state,
        reviews: state.reviews.filter(
          (review) => review._id !== action.payload
        ),
      };
    default:
      return {
        ...state,
      };
  }
};

export default reviewReducer;
