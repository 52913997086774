import {
  GET_EDU_NOTICE,
  GET_GOVT_NOTICE,
  POST_EDU_NOTICE,
  POST_GOVT_NOTICE,
  DELETE_EDU_NOTICE,
  DELETE_GOVT_NOTICE,
} from "./notice.type";

const initialState = {
  notices: [],
};

const noticeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EDU_NOTICE:
      return {
        ...state,
        eduNotices: [...action.payload],
      };
    case GET_GOVT_NOTICE:
      return {
        ...state,
        govtNotices: [...action.payload],
      };
    case POST_EDU_NOTICE:
      return {
        ...state,
        notices: [action.payload, ...state.notices],
      };
    case POST_GOVT_NOTICE:
      return {
        ...state,
        notices: [action.payload, ...state.notices],
      };
    case DELETE_EDU_NOTICE:
      return {
        ...state,
        eduNotices: state.eduNotices.filter(
          (notice) => notice._id !== action.payload
        ),
      };
    case DELETE_GOVT_NOTICE:
      return {
        ...state,
        govtNotices: state.govtNotices.filter(
          (notice) => notice._id !== action.payload
        ),
      };
    default:
      return {
        ...state,
      };
  }
};

export default noticeReducer;
