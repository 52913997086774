import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

// redux
import { useDispatch } from "react-redux";
import { postDigiService } from "../../../redux/reducers/services/service.action";

const DigiServiceModal = ({ isOpen, setIsOpen }) => {
  const [digiServiceData, setDigiServiceData] = useState({
    img: "",
    title: "",
    description: "",
  });

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setDigiServiceData((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setDigiServiceData({ ...digiServiceData, img: base64 });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submit = () => {
    dispatch(postDigiService({ ...digiServiceData })).then((response) => {
      console.log(response);
      if (response.type === "ERROR") {
        if (response.payload.response.status === 500) {
          alert(response.payload.response.data.error);
        } else if (response.payload.response.status === 413) {
          alert("Image size exceeded");
        }
      }
    });
    closeModal();
    setDigiServiceData({
      img: "",
      title: "",
      description: "",
    });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add Digi Service
                  </Dialog.Title>

                  <div className="mt-2 flex flex-col gap-4">
                    <form className="flex flex-col gap-4">
                      <div className="w-full flex flex-col gap-2">
                        <label htmlFor="image">Image</label>
                        <input
                          type="file"
                          id="image"
                          accept=".jpeg, .jpg, .png"
                          onChange={(e) => handleFileUpload(e)}
                          className="w-full border border-gray-400 px-3 py-2 rounded-lg focus:outline focus:border-blue-400"
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2">
                        <label htmlFor="title">Service Name</label>
                        <input
                          type="text"
                          id="title"
                          placeholder="Add context..."
                          value={digiServiceData.title}
                          onChange={handleChange}
                          className="w-full border border-gray-400 px-3 py-2 rounded-lg focus:outline focus:border-blue-400"
                        />
                      </div>
                      <div className="w-full flex flex-col gap-2">
                        <label htmlFor="description">Description</label>
                        <textarea
                          type="text"
                          id="description"
                          rows={5}
                          placeholder="Add review..."
                          value={digiServiceData.description}
                          onChange={handleChange}
                          className="w-full border border-gray-400 px-3 py-2 rounded-lg focus:outline focus:border-blue-400"
                        />
                      </div>
                    </form>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={submit}
                    >
                      Add
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default DigiServiceModal;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
