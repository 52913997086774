import React from "react";
import Aos from "aos";
import emailjs from "@emailjs/browser";
import "aos/dist/aos.css";
import { ImLocation } from "react-icons/im";
import { IoIosTime } from "react-icons/io";
import { GoMail } from "react-icons/go";
import { CgPhone } from "react-icons/cg";
import { useEffect, useRef } from "react";

const Contect = () => {
  useEffect(() => {
    Aos.init();
  });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          alert("Message Sent");
          var form = document.querySelector("#contact-form");
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <section id="contact">
        <div id="contect" className="flex justify-center items-center mt-8">
          <span
            className="text-center text-sky-500 font-semibold md:text-5xl text-4xl mx-auto"
            data-aos="fade-up"
          >
            Contact Us
          </span>
        </div>
        <div
          id="container"
          className="flex lg:flex-nowrap flex-wrap justify-between items-center gap-1 px-2 py-5 w-full lg:h-[31rem]"
        >
          <div
            id="info"
            data-aos="fade-up"
            className="flex flex-col justify-center gap-8 bg-blue-900 p-8 lg:w-1/2 w-[full] sm:w-[650px] m-4 px-16 rounded-xl h-full"
          >
            <div className="flex  items-center gap-1" data-aos="fade-right">
              <ImLocation className="text-white text-3xl" />
              <div className="flex flex-col justify-center  gap-1 px-5 py-2">
                <h3 className="text-2xl text-white font-medium">
                  Our Office Address
                </h3>
                <span className="text-sm text-gray-300 ">
                  Digi Cyber Cafe, Kadipur Rd, near Govt. School, IOC Colony,
                  Sector 10A, Gurugram, Haryana 122001, India
                </span>
              </div>
            </div>
            <div className="flex  items-center gap-1" data-aos="fade-right">
              <GoMail className="text-white text-3xl" />
              <div className="flex flex-col justify-center  gap-1 px-5 py-2">
                <h3 className="text-2xl text-white font-medium">
                  General Enquiry
                </h3>
                <span className="text-sm text-gray-300 ">
                  help.dcsgroup@gmail.com
                </span>
              </div>
            </div>
            <div className="flex  items-center gap-1" data-aos="fade-right">
              <CgPhone className="text-white text-3xl" />
              <div className="flex flex-col justify-center  gap-1 px-5 py-2">
                <h3 className="text-2xl text-white font-medium">Call Us</h3>
                <span className="text-sm text-gray-500 ">+91-7017200216</span>
              </div>
            </div>
            <div className="flex  items-center gap-1" data-aos="fade-right">
              <IoIosTime className="text-white text-3xl" />
              <div className="flex flex-col justify-center  gap-1 px-5 py-2">
                <h3 className="text-2xl text-white font-medium">Our Timing</h3>
                <span className="text-sm text-gray-500 ">
                  Mon - Sun : 10:00 AM - 08:00 PM
                </span>
              </div>
            </div>
          </div>
          <div
            id="inputs"
            data-aos="fade-up"
            className="flex flex-col justify-center items-center gap-8 bg-blue-900 p-8 lg:w-1/2 w-full m-4 rounded-xl h-full"
          >
            <form
              ref={form}
              className="w-full"
              id="contact-form"
              onSubmit={sendEmail}
            >
              <div className="">
                <div className="flex flex-col gap-4 " data-aos="fade-up">
                  <input
                    type="text"
                    name="client_name"
                    id="name"
                    placeholder="Your Name"
                    required
                    className="inputs_design"
                    data-aos="fade-right"
                  />
                  <input
                    type="text"
                    name="client_email"
                    id="email"
                    placeholder="Your Email"
                    required
                    className="inputs_design"
                    data-aos="fade-right"
                  />
                  <input
                    type="number"
                    name="client_mobile"
                    id="mNumber"
                    placeholder="Your Contact"
                    required
                    className="inputs_design"
                    data-aos="fade-right"
                  />
                  <textarea
                    name="message"
                    placeholder="Query..."
                    className="inputs_design textarea_design"
                    data-aos="fade-right"
                  ></textarea>
                </div>
              </div>
              <div className="w-full">
                <input
                  className="submit_button mt-4 mx-auto"
                  type="submit"
                  value="Send"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contect;
