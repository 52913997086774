import axios from "axios";

export const getReview = () => async (dispatch) => {
  try {
    const reviewList = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/review`,
    });

    return dispatch({ type: "GET_REVIEW", payload: reviewList.data.reviews });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const postReview = (reviewData) => async (dispatch) => {
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/review/new`,
      data: { reviewData },
    });

    return dispatch({ type: "POST_REVIEW", payload: reviewData });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const deleteReview = (_id) => async (dispatch) => {
  try {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/review/delete/${_id}`,
    });
    console.log(_id);

    return dispatch({ type: "DELETE_REVIEW", payload: _id });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
