import React from "react";
import { FaDirections } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const MapView = (props) => {
  const { mapLocation } = props;
  const position = [28.450319, 77.003713];
  return (
    <>
      <div className="mt-5">
        <div className="w-full h-60">
          <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={false}
            className="h-full z-10"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {mapLocation.map((each, index) => (
              <Marker position={[each.lat, each.lang]} key={index}>
                <Popup>{each.shop}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${props.latAndLong}`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2 px-3 py-2 text-gray-700 border border-gray-400 rounded-lg  mx-auto my-4"
        >
          <span className="text-sky-400">
            <FaDirections />
          </span>{" "}
          Direction
        </a>
      </div>
    </>
  );
};

const Map = () => {
  const mapLocations = [
    { shop: "Digi Service Center 1", lat: "28.450510", lang: "77.002658" },
    { shop: "Digi Service Center 2", lat: "28.450319", lang: "77.003713" },
  ];
  return (
    <>
      <section id="location">
        <MapView
          phno="+91-9988943955"
          mapLocation={mapLocations}
          address="kadipur"
          latAndLong="[28.450319, 77.003713]"
        />
      </section>
    </>
  );
};

export default Map;
