import React from "react";
import Navbar from "../components/HeroSection/Navbar";
import AddDigiService from "../components/Services/AddDigiService";
import AddPrintService from "../components/Services/AddPrintService";
import AddEduNotice from "../components/notice/AddEduNotice";
import AddGovtNotice from "../components/notice/AddGovtNotice";

const LoginPage = () => {
  return (
    <>
      <Navbar />
      <AddDigiService />
      <AddPrintService />
      <AddEduNotice />
      <AddGovtNotice />
    </>
  );
};

export default LoginPage;
