import React, { useState, useEffect } from "react";
import DigiServiceCard from "./DigiServiceCard";

// redux
import { useSelector } from "react-redux";

const DigiServices = () => {
  const [open, setOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const handleToggleButton = (index) => {
    if (open === index) {
      return setOpen(null);
    }
    setOpen(index);
  };

  const reduxState = useSelector(
    (globalState) => globalState.services.digiServices
  );

  useEffect(() => {
    reduxState && setServiceList(reduxState);
  }, [reduxState]);

  return (
    <>
      <section id="services" className="container mx-auto mt-28">
        <div id="service" className="flex justify-center items-center my-9">
          <span
            className="text-center text-sky-500 font-semibold md:text-4xl text-3xl  mx-auto"
            data-aos="fade-up"
          >
            Services We Provide
          </span>
        </div>
        <div
          className="flex flex-col gap-2 px-7 w-full py-2"
          data-aos="fade-up"
        >
          {serviceList.map((data, index) => (
            <DigiServiceCard
              {...data}
              key={index}
              open={index === open}
              handleToggleButton={() => handleToggleButton(index)}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default DigiServices;
