import axios from "axios";

export const getDigiService = () => async (dispatch) => {
  try {
    const serviceList = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/digiService/getDigiService`,
    });
    return dispatch({
      type: "GET_DIGI_SERVICE",
      payload: serviceList.data.service,
    });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};

export const getPrintService = () => async (dispatch) => {
  try {
    const serviceList = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/printService/getPrintService`,
    });
    return dispatch({
      type: "GET_PRINT_SERVICE",
      payload: serviceList.data.service,
    });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};
export const postDigiService = (digiServiceData) => async (dispatch) => {
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/digiService/newDigiService`,
      data: { digiServiceData },
    });
    return dispatch({
      type: "POST_DIGI_SERVICE",
      payload: digiServiceData,
    });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};
export const postPrintService = (printServiceData) => async (dispatch) => {
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/printService/newPrintService`,
      data: { printServiceData },
    });
    return dispatch({
      type: "POST_PRINT_SERVICE",
      payload: printServiceData,
    });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};
export const deleteDigiService = (_id) => async (dispatch) => {
  try {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/digiService/delete/${_id}`,
    });

    return dispatch({ type: "DELETE_DIGI_SERVICE", payload: _id });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const deletePrintService = (_id) => async (dispatch) => {
  try {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/printService/delete/${_id}`,
    });

    return dispatch({ type: "DELETE_PRINT_SERVICE", payload: _id });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
