import axios from "axios";

export const getEduNotice = () => async (dispatch) => {
  try {
    const noticeList = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/eduNotice/getEduNotice`,
    });

    return dispatch({
      type: "GET_EDU_NOTICE",
      payload: noticeList.data.notice,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const getGovtNotice = () => async (dispatch) => {
  try {
    const noticeList = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/govtNotice/getGovtNotice`,
    });

    return dispatch({
      type: "GET_GOVT_NOTICE",
      payload: noticeList.data.notice,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const postEduNotice = (noticeData) => async (dispatch) => {
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/eduNotice/newEduNotice`,
      data: { noticeData },
    });

    return dispatch({ type: "POST_REVIEW", payload: noticeData });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const postGovtNotice = (noticeData) => async (dispatch) => {
  try {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/govtNotice/newGovtNotice`,
      data: { noticeData },
    });

    return dispatch({ type: "POST_REVIEW", payload: noticeData });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteEduNotice = (_id) => async (dispatch) => {
  try {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/eduNotice/delete/${_id}`,
    });

    return dispatch({ type: "DELETE_EDU_NOTICE", payload: _id });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const deleteGovtNotice = (_id) => async (dispatch) => {
  try {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/govtNotice/delete/${_id}`,
    });

    return dispatch({ type: "DELETE_GOVT_NOTICE", payload: _id });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
