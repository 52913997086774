import React from "react";
import { Collapse } from "react-collapse";
import { BiSolidChevronDown, BiSolidChevronUp } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
// import OurServices from "../../data/servicesData";

// redux
import { useSelector, useDispatch } from "react-redux";
import { deleteDigiService } from "../../redux/reducers/services/service.action";

const DigiServiceCard = (props) => {
  const { open, handleToggleButton, img, title, description } = props;
  const user = useSelector((globalState) => globalState.user);

  const dispatch = useDispatch();
  const deleteServiceCard = (_id) => {
    dispatch(deleteDigiService(_id));
  };

  const goToWhatsapp = () => {
    const url = `https://wa.me/917017200216?text=Query Related to :`;
    window.open(url, "_blank").focus();
  };

  return (
    <>
      <div className="  bg-gradient-to-r from-blue-700 via-violet-600 to-blue-300 w-[21rem] pt-1 rounded-md group sm:w-[36.5rem] md:w-[25rem] lg:w-[30rem] xl:w-[50rem] ">
        <div className="flex justify-start gap-2 p-2 shadow-lg bg-white rounded-md  ">
          <div className="md:ml-0  xl:ml-2">
            <div className=" mx-auto bg-gradient-to-r from-blue-700 via-violet-600 to-blue-300 rounded-full p-1 w-[3.4rem]">
              <img
                src={img}
                alt="Service"
                className="w-12 h-12 rounded-full object-contain  "
              />
            </div>
          </div>
          <div className="flex flex-col justify-start md:ml-2 xl:ml-5 w-full">
            <div
              className="flex justify-between cursor-pointer "
              onClick={handleToggleButton}
            >
              <h3 className="text-xl align-middle font-semibold font-serif py-2 group-[.is-active]:font-bold ">
                {title}
              </h3>
              <div className="mx-10 text-xl my-3  cursor-pointer">
                {open ? <BiSolidChevronDown /> : <BiSolidChevronUp />}
              </div>
            </div>
            <Collapse isOpened={open}>
              <p className={`text-blue-600 py-5`}>{description}</p>
              <div className="flex gap-1">
                <div
                  className="bg-sky-500 text-center rounded-lg text-white lg:text-md px-3 py-2 mx-auto"
                  onClick={goToWhatsapp}
                >
                  <span className="flex gap-2 items-center justify-center">
                    <BsWhatsapp /> Enquiry Now
                  </span>
                </div>
                {user?.fullName ? (
                  <button
                    className="text-red-700 border border-red-700 text-xl py-2 rounded-lg px-4"
                    onClick={() => deleteServiceCard(props._id)}
                  >
                    <AiFillDelete />
                  </button>
                ) : null}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      <div />
    </>
  );
};

export default DigiServiceCard;
