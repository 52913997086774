import React, { useState, useEffect } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
// import Marquee from "react-fast-marquee";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// redux
import { useSelector, useDispatch } from "react-redux";
import { deleteGovtNotice } from "../../redux/reducers/notice/notice.action";

dayjs.extend(relativeTime);

const GovtNotice = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const reduxState = useSelector(
    (globalState) => globalState.notices.govtNotices
  );

  const user = useSelector((globalState) => globalState.user);

  useEffect(() => {
    reduxState && setNoticeList(reduxState);
  }, [reduxState]);

  const dispatch = useDispatch();
  const deleteNotice = (_id) => {
    dispatch(deleteGovtNotice(_id));
  };
  return (
    <div className="mx-4 my-5 mt-28">
      <div className=" w-full rounded-lg border-2 border-blue-900">
        <div className="flex justify-center bg-blue-900">
          <h1 className="  text-3xl text-white rounded-md font-bold py-2">
            Job Notice
          </h1>
        </div>
        <div
          className={`shadow-xl h-96 bg-sky-100  relative marquee-container ${
            isHovered ? "hovered" : ""
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="marquee-content w-full">
            {noticeList.map((notice, key) => (
              <div className="flex justify-between mx-3 my-3" key={key}>
                <div className="flex">
                  <MdArrowRightAlt className="text-xl text-green-500 items-center my-auto mx-1" />

                  <a
                    href={notice.link}
                    target="_blank"
                    rel="noreferrer"
                    className="border-blue-900 w-96  text-md  cursor-pointer truncate"
                  >
                    {notice.notice}
                  </a>
                </div>
                <span className="border-blue-900  px-2 text-sm text-gray-700">
                  {dayjs(notice.createdAt).format("DD/MM/YYYY")}
                </span>
                {user?.fullName ? (
                  <button
                    className="text-red-500 border border-red-500 text-xl py-2 rounded-lg px-4"
                    onClick={() => deleteNotice(notice._id)}
                  >
                    <AiFillDelete />
                  </button>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovtNotice;
