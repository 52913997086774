import { combineReducers } from "redux";

// reducers or storage units
import auth from "./auth/auth.reducer";
import user from "./user/user.reducer";
import services from "./services/service.reducer";
import reviews from "./reviews/review.reducer";
import notices from "./notice/notice.reducer";

const rootReducer = combineReducers({ auth, user, services, reviews, notices });

export default rootReducer;
