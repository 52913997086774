import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../css/hero_section.css";
// import Navbar from "./Navbar";
// import ContactBar from "./ContactBar";

const HeroSection = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <section className="hero_section">
        <React.Fragment>
          <div className="flex flex-col justify-center items-center gap-1 py-32 font-semibold w-full">
            <span className="text-white text-center lg:text-5xl md:text-4xl text-xl w-3/4">
              One Point Solution For All Online Services
            </span>
            <a
              href="https://wa.me/917017200216"
              target="_blank"
              rel="noreferrer"
              data-aos="fade-up"
              className="bg-indigo-700 rounded-full text-white lg:text-md px-4 py-2 my-8"
            >
              Enquiry Now
            </a>
          </div>
        </React.Fragment>
      </section>
    </>
  );
};

export default HeroSection;
// <ContactBar />
// <Navbar />
