import axios from "axios";

// redux types
import { SIGN_IN } from "./auth.type";

export const signIn = (userData) => async (dispatch) => {
  try {
    const User = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/auth/signin`,
      data: { credentials: userData },
    });
    localStorage.setItem(
      "digiUser",
      JSON.stringify({ token: User.data.token })
    );

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${User.data.token}`;

    return dispatch({ type: SIGN_IN, payload: User.data });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const signOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("digiUser");

    return dispatch({ type: "SIGN_OUT", payload: {} });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
