import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <section id="about">
        <div
          id="container"
          className="flex flex-wrap justify-center items-center gap-4 bg-sky-50 w-full"
        >
          <div
            id="content"
            data-aos="fade-up"
            className="flex flex-col justify-center items-center gap-5 md:w-[47%]"
          >
            <div
              id="about"
              className="flex justify-center items-center md:mt-6 mt-3"
            >
              <span
                className="text-center text-sky-500 font-semibold text-4xl mx-auto"
                data-aos="fade-up"
              >
                About Us
              </span>
            </div>
            <div id="info" className="text-center  text-md md:px-8 px-5 py-2">
              <span className="px-2">
                Digi Common Services are crucial enablers of Digital Online
                Services. We are delivering various e-governance and business
                services to the customers. We have more then 4 year of
                experiance in the field of online services. Served more then
                five thosusand customers. We have expert to consult you in
                better way. Give a chance to serve you once.
              </span>
            </div>
          </div>
          <div id="image" className="md:w-[47%] md:p-7 px-5" data-aos="fade-up">
            <img
              src="/images/aboutImage.jpg"
              alt="about"
              className="rounded-xl "
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
